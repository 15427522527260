.wrapper.wrapper {
  position: absolute;
  z-index: 2;
  padding: 30px 20px;
  background: var(--gray) !important;
  color: var(--white) !important;
  box-sizing: border-box;
  right: 0;
  box-shadow: none !important;

  top: 0;
  width: 200px;
  height: 100%;
  border-radius: 0;
}

@media (min-width: 768px) {
  .wrapper.wrapper {
    width: 300px;
  }
}

@media (min-width: 1024px) {
  .wrapper.wrapper {
    top: 125px;
    width: 400px;
    height: max-content;
    border-radius: 10px 0 0 10px;
  }
}

.wrapper h1 {
  margin: 0;
  font-weight: 500;
}

.wrapper h2 {
  margin-bottom: 10px;
  margin-top: 0;
}

.wrapper h3 {
  margin-top: 0;
  margin-bottom: 30px;
}

.ctas_wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (min-width: 1024px) {
  .ctas_wrapper {
    flex-direction: row;
  }
}

.ctas_wrapper > a {
  width: 90px;
  height: 25px;
}

@media (min-width: 768px) {
  .ctas_wrapper > a {
    width: 130px;
    height: 100%;
  }
}

.ctas_wrapper.ctas_wrapper svg {
  font-size: 1rem;
}
