html {
  background: var(--bg-white);
}

body {
  margin: 0;
  color: var(--white);
}

body * {
  font-family: "Oswald", sans-serif !important;
  font-weight: 300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  padding: 0 0 30px 0;
}

@media (min-width: 1024px) {
  #root {
    padding: 30px 0;
  }
}

/* Generic Classes */
.display-none {
  display: none;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.17rem;
}

:root {
  background: var(--gray);
  font-size: 10px;

  /* Variables */
  --bg-white: #f3f3f2;
  --black: #090a0f;
  --gray: #23262c;
  --white: #fff;
  --amber: #814a4a;
  --green: #4d615d;
}

@media (min-width: 768px) {
  :root {
    font-size: 16px;
  }
}
