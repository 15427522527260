.add_form {
  margin-top: 40px;
}

.projects_wrapper {
  width: 100%;
  height: max-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}
