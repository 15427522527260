.wrapper {
  height: 160px;
  width: 160px;
  position: relative;
  border-radius: 10px !important;
}

@media (min-width: 768px) {
  .wrapper {
    height: 250px;
    width: 250px;
  }
}

.images_wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
}

.images_wrapper > i {
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.75;
}

.background {
  object-fit: cover;
  height: 100%;
  width: 100%;
  filter: grayscale(1);
}

.icon {
  position: absolute;
  height: 30%;
  width: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  filter: brightness(0) invert(1);
}

.icon_mini {
  position: absolute;
  height: 2rem;
  width: auto;
  object-fit: contain;
  margin: auto;
  top: -2.5rem;
  filter: brightness(0) invert(1);
}

.icon_mini:global.animate__fadeIn {
  animation-delay: 0.5s;
}

.text_wrapper {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 2;
}

.text_wrapper > h2 {
  position: absolute;
  color: var(--white);
  font-weight: 400;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  white-space: nowrap;
}

.hidden_text_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% - 3rem);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 20px 10px;
  box-sizing: border-box;
  text-align: center;
  background: white;
  margin-top: 3rem;
}

.hidden_text_wrapper > div {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 10px;
}

.hidden_text_wrapper > h3 {
  margin: 0;
}

.hidden_text_wrapper a {
  width: 100px;
}

@media (max-width: 767px) {
  .hidden_text_wrapper a {
    max-width: 30px;
    min-width: 30px;
    min-height: 30px;
    max-height: 30px;
    padding: 0;
  }

  .hidden_text_wrapper span {
    margin: 0;
  }
}
