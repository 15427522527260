.wrapper {
  background-color: var(--white);
  color: rgba(0, 0, 0, 0.6);
  padding: 20px;
  display: flex;
  flex-basis: 50%;
  flex-direction: row !important;
  gap: 20px;
}

.wrapper > * {
  margin: 0 !important;
}
