.wrapper {
  display: table;
  text-align: center;
  width: 85%;
  margin: 10px auto 20px;
}

.wrapper > span {
  font-size: 3rem;
  font-weight: 500;
}
