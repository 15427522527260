.wrapper {
  display: flex !important;
  justify-content: flex-end;
  z-index: 3;
  padding-top: 5px;
}

.wrapper button {
  color: var(--white);
  font-size: 22px;
}

.top_wrapper {
  width: 400px;
  display: flex;
  justify-content: space-between;
}
