.image {
  width: 100%;
  object-fit: contain;
  top: 0;
  left: -20px;
  position: relative;
}

@media (min-width: 768px) {
  .image {
    top: -50px;
    left: 0;
  }
}

.wrapper {
  position: relative;
  width: 100%;
  height: 250px;
  overflow: hidden;
}

@media (min-width: 768px) {
  .wrapper {
    height: 350px;
  }
}

@media (min-width: 1024px) {
  .wrapper {
    height: 500px;
    border-radius: 10px;
  }
}
